import { ApolloError, gql as shopifyGQL, useQuery } from '@apollo/client'
import { getShopifyClient } from '@/services/ApolloClient/ApolloClient'
import { createShopifyClient } from '@/services/Shopify/ShopifyClient'
import { GetShopifyCollection, GetShopifyProduct } from '@/services/Shopify/queries'
import {
  GetShopifyCollectionQuery,
  GetShopifyCollectionQueryVariables,
  GetShopifyProductByIdQuery,
  GetShopifyProductByIdQueryVariables,
  Product,
} from '@/types/codegen-shopify'

interface UseCollectionResponse {
  data?: {
    collection: {
      id: string
      handle: string
      title: string
      description: string
      updatedAt: string
      products: {
        edges: [
          {
            node: ShopifyProduct
          },
        ]
      }
    }
  }
  loading: boolean
  error?: ApolloError
}

export interface ShopifyProduct {
  id: string
  title: string
  description: string
  availableForSale: boolean
  compareAtPriceRange: {
    maxVariantPrice: {
      amount: number
      currencyCode: string
    }
    minVariantPrice: {
      amount: number
      currencyCode: string
    }
  }
  priceRange: {
    maxVariantPrice: {
      amount: number
      currencyCode: string
    }
    minVariantPrice: {
      amount: number
      currencyCode: string
    }
  }
  featuredImage: {
    width: number
    height: number
    url: string
  }
  onlineStoreUrl: string
}

const collectionByHandleQuery = shopifyGQL`
  query getCollectionByHandle($handle: String!) {
    collection(handle: $handle) {
      id
      handle
      title
      description
      updatedAt
      products(first: 150) {
        edges {
          node {
            id
            title
            description
            availableForSale
            compareAtPriceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            productType
            tags
            featuredImage {
              height
              width
              altText
              url
            }
            onlineStoreUrl
          }
        }
      }
    }
  }
`

const updatedCollectionByHandleQuery = shopifyGQL`
query getCollection($handle: String!) {
  collection: collectionByHandle(handle: $handle) {
    id
    handle
    title
    description
    updatedAt
    products(first: 150, sortKey: MANUAL, reverse:false) {
      edges {
        node {
          id
          title
          description
          compareAtPriceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          productType
          tags
          featuredImage {
            height
            width
            altText
            url
          }
          onlineStoreUrl
        }
      }
    }
  }
}
`

interface useCollectionProps {
  handle: string
  storeUrl: string
  accessToken: string
}

export function useShopifyCollection({ handle, storeUrl, accessToken }: useCollectionProps): UseCollectionResponse {
  const shopifyClient = createShopifyClient({ baseUrl: storeUrl, accessToken })
  const shopifyQuery = storeUrl.includes('2024-04') ? updatedCollectionByHandleQuery : collectionByHandleQuery

  const result = useQuery(shopifyQuery, {
    variables: {
      handle: handle,
    },
    client: shopifyClient,
    errorPolicy: 'all',
  })

  return result
}

export async function getShopifyProduct({ productId }: { productId: string }) {
  const shopifyClient = getShopifyClient()
  const { data } = await shopifyClient.query<GetShopifyProductByIdQuery, GetShopifyProductByIdQueryVariables>({
    query: GetShopifyProduct,
    variables: {
      id: productId,
    },
    fetchPolicy: 'network-only',
  })

  return data?.product
}

export interface ShopifyProductWithBundles extends Product {
  bundledProducts: { quantity: number; product: Product }[]
}
export async function getShopifyCollection({ collectionId }: { collectionId: string }) {
  const shopifyClient = getShopifyClient()
  const { data } = await shopifyClient.query<GetShopifyCollectionQuery, GetShopifyCollectionQueryVariables>({
    query: GetShopifyCollection,
    variables: {
      id: collectionId,
    },
    fetchPolicy: 'network-only',
  })
  const products = data?.collection?.products?.edges?.map((edge) => edge.node)

  return products?.map((product) => {
    const bundledProducts = product?.variants?.edges?.flatMap((edge) => {
      return edge?.node?.components?.edges?.flatMap((e) => {
        return { quantity: e?.node?.quantity, product: e?.node?.productVariant?.product }
      })
    })
    return { ...product, bundledProducts: bundledProducts ?? [] }
  })
}
