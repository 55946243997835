import React from 'react'
import { getHexColor, OneColorIconProps } from '@/atoms/utils'

export const SharpPlusIcon: React.FC<OneColorIconProps> = ({ size = 16, color = 'gray-100', className }) => {
  const stroke = getHexColor(color)

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M2.5 8H13.5" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 2.5V13.5" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
