import React, { useCallback } from 'react'
import classNames from 'classnames'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'

enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
  '3xl' = '3xl',
}

interface Props {
  src: string
  className?: string
  dataCy?: string
  transforms?: string
  responsiveAR?: {
    [key in Breakpoints]?: string | number
  }
  srcUrls?: {
    [key in Breakpoints]?: string
  }
  backgroundPosition?:
    | 'bg-bottom'
    | 'bg-center'
    | 'bg-left'
    | 'bg-left-bottom'
    | 'bg-left-top'
    | 'bg-right'
    | 'bg-right-bottom'
    | 'bg-right-top'
    | 'bg-top'
  backgroundSize?: 'bg-cover' | 'bg-contain'
  preBackgroundStyles?: string
  gravity?: string
}

export const ResponsiveBackgroundImage: React.FC<Props> = ({
  src,
  srcUrls,
  className = '',
  dataCy,
  transforms = '',
  responsiveAR,
  backgroundPosition = 'bg-center',
  backgroundSize = 'bg-cover',
  preBackgroundStyles = '',
  gravity = '',
}) => {
  const sharedClasses = classNames(
    'absolute top-0 left-0 right-0 bottom-0 bg-no-repeat',
    backgroundPosition,
    backgroundSize,
  )

  const getBackgroundImage = useCallback(
    (width: number, ar?: string | number, srcUrl = src) => {
      const internalAR = ar ?? '16:9'
      return `${preBackgroundStyles}url('${getCloudinaryImageUrl({
        transforms: `q_auto,ar_${internalAR},c_fill${gravity ? `,${gravity}` : ''}${width === 640 ? ',g_face' : ''}${
          transforms ? `/${transforms}` : '/'
        }`,
        width,
        path: srcUrl,
      })}')`
    },
    [transforms, src, preBackgroundStyles, gravity],
  )

  return (
    <>
      <div
        data-testid={dataCy}
        className={classNames(sharedClasses, className, 'sm:hidden')}
        style={{
          backgroundImage: getBackgroundImage(640, responsiveAR?.[Breakpoints.xs] || '1', srcUrls?.[Breakpoints.xs]),
        }}
      />
      <div
        data-testid={dataCy}
        className={classNames(sharedClasses, className, 'hidden sm:block md:hidden')}
        style={{
          backgroundImage: getBackgroundImage(768, responsiveAR?.[Breakpoints.sm] || '1', srcUrls?.[Breakpoints.sm]),
        }}
      />
      <div
        data-testid={dataCy}
        className={classNames(sharedClasses, className, 'hidden md:block lg:hidden')}
        style={{
          backgroundImage: getBackgroundImage(
            1024,
            responsiveAR?.[Breakpoints.md] || '16:9',
            srcUrls?.[Breakpoints.md],
          ),
        }}
      />
      <div
        data-testid={dataCy}
        className={classNames(sharedClasses, className, 'hidden lg:block xl:hidden')}
        style={{
          backgroundImage: getBackgroundImage(
            1280,
            responsiveAR?.[Breakpoints.lg] || '16:9',
            srcUrls?.[Breakpoints.lg],
          ),
        }}
      />
      <div
        data-testid={dataCy}
        className={classNames(sharedClasses, className, 'hidden xl:block 2xl:hidden')}
        style={{
          backgroundImage: getBackgroundImage(
            1536,
            responsiveAR?.[Breakpoints.xl] || '16:9',
            srcUrls?.[Breakpoints.xl],
          ),
        }}
      />
      <div
        data-testid={dataCy}
        className={classNames(sharedClasses, className, 'hidden 2xl:block 3xl:hidden')}
        style={{
          backgroundImage: getBackgroundImage(
            2100,
            responsiveAR?.[Breakpoints['2xl']] || '16:9',
            srcUrls?.[Breakpoints['2xl']],
          ),
        }}
      />
      <div
        data-testid={dataCy}
        className={classNames(sharedClasses, className, 'hidden 3xl:block')}
        style={{
          backgroundImage: getBackgroundImage(
            3440,
            responsiveAR?.[Breakpoints['3xl']] || '16:9',
            srcUrls?.[Breakpoints['3xl']],
          ),
        }}
      />
    </>
  )
}
